<template>
  <div class="page">
    <navbar />
    <div class="box">
      <div v-if="searchParams.search_text" class="search-word">
        {{ searchParams.search_text }}
      </div>
      <div class="list">
        <div class="tab">
          <div
            class="tab-item"
            :class="{ active: activeTab === TYPES.all }"
            @click="changeType(TYPES.all)"
          >
            全部
          </div>
          <div
            class="tab-item"
            :class="{ active: activeTab === TYPES.free }"
            @click="changeType(TYPES.free)"
          >
            免费
          </div>
          <div
            class="tab-item"
            :class="{ active: activeTab === TYPES.charge }"
            @click="changeType(TYPES.charge)"
          >
            收费
          </div>
        </div>
        <div class="color" />
        <van-list
          v-model="loading"
          class="result-list"
          :finished="finished"
          finished-text="没有更多了"
          @load="fetchList"
        >
          <div
            v-for="item in resultList"
            :key="item.id"
            @click="navigateToDetail(item.id, item.price)"
            class="list-item"
          >
            <div class="item-top">
              <div class="thumb">
                <img :src="item.cover_image" alt="" />
                <div v-if="readed.has(item.id)" class="watched">已查看</div>
              </div>
              <div class="right-content">
                <div class="content-inner">
                  <div class="item-info">
                    <span class="label">
                      题名：
                    </span>
                    <span class="value">
                      {{ item.title }}（{{ item.juanming }}）
                    </span>
                  </div>
                  <div class="item-info">
                    <span class="label">
                      堂号：
                    </span>
                    <span class="value">
                      {{ item.tanghao || "无" }}
                    </span>
                  </div>
                  <div class="item-info">
                    <span class="label">
                      卷数：
                    </span>
                    <span class="value">
                      {{ item.juanshu || "无" }}
                    </span>
                  </div>
                  <div class="item-info">
                    <span class="label">
                      谱籍地：
                    </span>
                    <span class="value">
                      {{ item.pujidi || "无" }}
                    </span>
                  </div>
                  <div class="item-info">
                    <span class="label">
                      编撰日期：
                    </span>
                    <span class="value">
                      {{ item.zhuanxieriqi || "无" }}
                    </span>
                  </div>
                </div>
                <img
                  v-if="item.price == 0"
                  class="corner"
                  src="@/assets/h5images/search-result/免费.png"
                />
                <img
                  v-else
                  class="corner"
                  src="@/assets/h5images/search-result/收费.png"
                />
              </div>
            </div>
            <div
              class="intro"
              v-if="item.description"
              :class="{ expanded: expanded.has(item.id) }"
              @click.stop="handleIntroClick(item.id)"
            >
              <div class="intro-left">
                <span>简介：</span>
                {{ item.description }}
              </div>
              <van-icon
                :name="expanded.has(item.id) ? 'arrow-up' : 'arrow-down'"
                size="18"
              />
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { List, Icon } from "vant";
import materialApis from "@/apis/material";
import Navbar from "@/components/h5/Navbar.vue";

const TYPES = {
  all: "all",
  free: "free",
  charge: "charge"
};

// const mockData = new Array(10).fill({}).map((item, index) => ({
//   ...item,
//   id: index + 1,
//   timing: "题目",
//   tanghao: "堂号",
//   fayuandi: "发源地",
//   zhuanxieriqi: "编撰日期",
//   price: [0, 1][index % 2],
//   cover: require("@/assets/images/search/cover.png")
// }));

export default {
  name: "SearchResult",
  components: {
    "van-list": List,
    [Icon.name]: Icon,
    Navbar
  },
  data() {
    return {
      TYPES,
      activeTab: TYPES.all,
      page: 1,
      loading: false,
      finished: false,
      resultList: [],
      searchParams: {},
      readed: new Set(),
      expanded: new Set()
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  mounted() {
    this.searchParams = { ...this.$route.query };
    const storedReaded = JSON.parse(
      localStorage.getItem("readed_pushu") || "[]"
    );
    this.readed = new Set(storedReaded);
  },
  // created() {
  //   this.fetchList();
  // },
  methods: {
    showSearch() {
      this.$refs["search-dialog"].show();
    },

    changeType(type) {
      this.activeTab = type;
      this.refreshList();
    },

    async fetchList() {
      // const params = { ...this.$route.query };
      // if (this.activeTab !== TYPES.all) {
      //   params.isfree = this.activeTab === TYPES.free ? 1 : 2;
      // }
      try {
        const {
          data: { material_list, max_page }
        } = await materialApis.searchMaterial({
          ...this.searchParams,
          isfree:
            this.activeTab === TYPES.all
              ? ""
              : this.activeTab === TYPES.free
              ? 1
              : 2,
          page: this.page
        });
        this.page += 1;
        if (this.page >= max_page) {
          this.finished = true;
        }
        this.resultList = [...this.resultList, ...material_list];
      } catch (error) {
        this.finished = true;
        console.error(error);
      }
      this.loading = false;
    },

    refreshList(params) {
      this.searchParams = { ...params };
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.resultList = [];
      this.fetchList();
    },

    navigateToDetail(id, price) {
      if (!this.userInfo.id && price > 0) {
        this.$router.replace("/h5/login");
        return;
      }
      this.readed.add(id);
      localStorage.setItem(
        "readed_pushu",
        JSON.stringify(Array.from(this.readed))
      );
      this.$router.push({
        path: "/h5/search/detail",
        query: { id }
      });
    },

    handleIntroClick(id) {
      console.log(id, this.expanded);
      this.expanded.has(id) ? this.expanded.delete(id) : this.expanded.add(id);
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss">
.van-list__loading {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.page {
  width: 1125px;
  // height: 2436px;
  background-color: #ffffff;
}

.box {
  background-size: contain;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  // background: linear-gradient(0deg, #fcead9 0%, #fdf2ea 100%);
  // background-image: url(./images/img_65772_1_0.png);
  overflow: hidden;
}

.search-word {
  font-size: 48px;
  color: #333333;
  padding: 0 40px;
  // margin-top: 20px;
}

.search-tool {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  // margin-top: 23px;
  padding: 37px 0;
  width: 100%;
  justify-content: center;
  background-color: #fff;
}

.logo {
  margin-right: 39px;
  width: 125px;
  height: 120px;
}

.search-wrap {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 39px;
  border-radius: 50px;
  background-color: #f7f7f7;
  width: 750px;
  height: 100px;
  overflow: hidden;
}

.search-icon {
  margin-right: 31px;
  margin-left: 36px;
  width: 51px;
  height: 50px;
}

.search-text {
  margin-top: -2px;
  text-decoration: none;
  line-height: 36px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #666666;
  font-size: 44px;
}

.user {
  width: 90px;
  height: 90px;
}

.list {
  // margin-top: 20px;
  background-color: #ffffff;
  width: 1125px;
  // height: 1816px;
  overflow: hidden;
}

.result-title {
  position: relative;
  margin-top: 41px;
  margin-left: 41px;
  max-width: 1072px;
  // height: 45px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  line-height: 48px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-size: 56px;
}

.tab {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  margin-top: 42px;
  margin-left: 40px;
  border-bottom: 1px solid #efefef;

  .tab-item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #f7f7f7;
    padding-right: 66px;
    padding-left: 65px;
    height: 90px;
    overflow: hidden;
    margin-top: -2px;
    text-decoration: none;
    line-height: 36px;
    letter-spacing: 0px;
    white-space: nowrap;
    color: #666666;
    font-size: 44px;

    &.active {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-direction: row;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #ca0a15;
      padding-right: 66px;
      padding-left: 65px;
      height: 90px;
      overflow: hidden;
      margin-top: -3px;
      text-decoration: none;
      line-height: 36px;
      letter-spacing: 0px;
      white-space: nowrap;
      color: #ffffff;
      font-size: 44px;
    }
  }
}

.result-list {
  width: 100%;
  min-height: calc(100vh - 570px);
  padding: 0 40px;
  box-sizing: border-box;
}

.list-item {
  position: relative;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 10px;
  background-color: #f7f7f7;
  // width: 100%;
  overflow: hidden;

  .item-top {
    display: flex;
    height: 400px;
    overflow: hidden;
  }

  .intro {
    height: 110px;
    border-top: 1px solid #b5b5b5;
    box-sizing: border-box;
    padding: 37px 20px;
    color: #666666;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: normal;
    // display: inline;
    span {
      color: #333333;
      display: inline;
    }

    .intro-left {
      font-size: 36px;
      width: calc(100% - 150px);
      display: inline;
      word-break: break-all;
      // line-height: 1.7;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.expanded {
      height: auto;
      align-items: flex-start;

      .intro-left {
        white-space: normal;
        line-height: 1.7;
      }
    }
  }
}

.thumb {
  flex: none;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  // margin-top: 20px;
  // margin-right: 41px;
  // margin-left: 20px;
  border-radius: 10px;
  background-color: #8b4134;
  overflow: hidden;
  position: relative;
  height: 360px;
  width: 360px;
  margin-top: 20px;
  margin-left: 20px;
  // flex: none;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    // position: absolute;
  }
}

.watched {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  // margin-top: 290px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  // width: 360px;
  width: 100%;
  height: 70px;
  overflow: hidden;
  color: #ffffff;
  font-size: 40px;
  line-height: 70px;
}

.right-content {
  flex: 1;
  padding: 52px 40px;
  width: calc(100% - 340px);
  height: 100%;
  box-sizing: border-box;

  .content-inner {
    width: 100%;
    height: 100%;
  }

  .item-info {
    // margin-top: 10px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-of-type {
      margin-top: 0;
    }

    .label {
      position: relative;
      text-decoration: none;
      line-height: 1.4;
      letter-spacing: 0px;
      white-space: nowrap;
      color: #333333;
      font-size: 36px;
    }

    .value {
      position: relative;
      margin-left: 2px;
      // max-width: 161px;
      // height: 34px;
      text-decoration: none;
      line-height: 1.4;
      letter-spacing: 0px;
      white-space: nowrap;
      color: #666666;
      font-size: 36px;
    }
  }
}

.block_3 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}

.corner {
  width: 138px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
}
</style>
